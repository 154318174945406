import { useState, useContext, useEffect } from "react";

import Card from "Clutch/Atoms/Card";
import TextLink from "Clutch/Atoms/TextLink";
import Tabs from "Clutch/Components/Tabs/Tabs";
import { useVehicleStore } from "Clutch/Stores/VehicleStore"
import "Clutch/Contexts/VehicleContext/Dynamic/SelectYearMakeModel";
import "Clutch/Contexts/VehicleContext/Dynamic/UpdateRaceTypes";
import { NavigationContext } from "Contexts/Navigation/NavigationContext";
import ClassNames from "classnames";
import useFeatureFlag from "Clutch/Hooks/useFeatureFlag/useFeatureFlag";
import { useHomePage } from "../../../../providers/HomePageProvider";
import styles from "./YmmSelector.module.scss";
import GarageTab from "./tab-content/GarageContent";
import RaceTypeTab from "./tab-content/RaceContent";
import StreetTab from "./tab-content/StreetContent";
import EngineTab from "./tab-content/EngineContent";
 
const selectorTabLookup = {
  street: "STREET",
  race: "RACE",
  garage: "GARAGE",
  engine: "ENGINE"
};

const YmmSelector = ({ audience, suppressOtherFitment }) => {
  const setModalTab = useVehicleStore(x => x.setCurrentTab)
  const hydrated = useVehicleStore(x => x.context.hydrated)
  const customerProjects = useVehicleStore(x => x.context.customerProjects)
  const { hasCustomerProjects } = useHomePage();
  const { setYmmModal: setModal, dispatch: navigationDispatch } = useContext(NavigationContext);
  const [selectedTab, setSelectedTab] = useState(
    hasCustomerProjects === true ? selectorTabLookup.garage : selectorTabLookup.street
  );

  let tabContent = [];
  const isStreetTabEnabled = selectedTab === selectorTabLookup.street;
  const isRaceTabEnabled = selectedTab === selectorTabLookup.race;
  const isGarageTabEnabled = selectedTab === selectorTabLookup.garage;
  const isEngineTabEnabled = selectedTab === selectorTabLookup.engine;

  const impressionDontDelete = useFeatureFlag("Engine_Fitment_Master_Flag");
  const customerProjectDrawerEnabled = useFeatureFlag(
    "FrontEnd_CustomerProject_Drawer_Enabled"
  );

  useEffect(() => {
    if (hydrated) {
      if (customerProjects?.length > 0) {
        setSelectedTab(selectorTabLookup.garage);
      } else {
        if (audience === "street" || audience === "default" || audience === "truck")
          setSelectedTab(selectorTabLookup.street);
        else if (audience === "race") setSelectedTab(selectorTabLookup.race);
      }
    }
  }, [hydrated, customerProjects, audience]);

  useEffect(() => {
    if (audience === "race") setModalTab("race");
    else setModalTab("street");
  }, []);

  if (isGarageTabEnabled) {
    tabContent = [
      {
        label: `My Garage (${customerProjects?.length ?? 0})`,
        key: selectorTabLookup.garage,
        content: <GarageTab isEnabled={isGarageTabEnabled}/>,
      },
    ];
  } else if (suppressOtherFitment) {
    if (audience === "race") {
      tabContent = [
        {
          label: "Race",
          key: selectorTabLookup.race,
          content: <RaceTypeTab isEnabled={isRaceTabEnabled} />,
        },
      ];
    } else {
      tabContent = [
        {
          label: "Street",
          key: selectorTabLookup.street,
          content: <StreetTab isEnabled={isStreetTabEnabled} />,
        },
      ];
    }
  } else if (customerProjectDrawerEnabled) {
    tabContent = [
      {
        label: "Street",
        key: selectorTabLookup.street,
        content: <StreetTab isEnabled={isStreetTabEnabled} />,
      },
      {
        label: "Race",
        key: selectorTabLookup.race,
        content: <RaceTypeTab isEnabled={isRaceTabEnabled} />,
      },
      {
        label: "Engine",
        key: selectorTabLookup.engine,
        content: <EngineTab isEnabled={isEngineTabEnabled} />,
      },
    ];
  } else if (isStreetTabEnabled || isRaceTabEnabled) {
    tabContent = [
      {
        label: "Street",
        key: selectorTabLookup.street,
        content: <StreetTab isEnabled={isStreetTabEnabled} />,
      },
      {
        label: "Race",
        key: selectorTabLookup.race,
        content: <RaceTypeTab isEnabled={isRaceTabEnabled} />,
      },
    ];
  } 
  
  return (
    <>
      <div className={`${styles.cardWrapper} bg-dark`}>
        <Card
          className={
            isGarageTabEnabled
              ? ClassNames(styles.garageSelector, "bg-dark")
              : ClassNames(styles.ymmSelector, "bg-dark")
          }
          fill
          noPadding
          backgroundColorOverride={"hsla(0,0%,100%,0.25)"}
        >
          <Tabs
            activeTab={selectedTab}
            intent={"subdued"}
            size={1}
            items={tabContent}
            onChange={(key) => setSelectedTab(key)}
            border
            dataTestId={"ymm"}
            selectLabel={'ymm'}
          />
        </Card>
      </div>
      {isGarageTabEnabled && (
        <TextLink
          className={` ${styles.addVehicleLink} p p-color-contrast`}
          onClick={(e) => {
            e.stopPropagation();
            if (customerProjectDrawerEnabled) {
              navigationDispatch({
                type: "setCustomerProjectDrawerState",
                payload: {
                  garageState: {
                    isCustomerProjectDrawerOpen: true
                  },
                },
              })
            } else {
              setModal(true);
            }
          }}
          data-testid={"addNewVehicleButton"}
        >
          + Add a New Vehicle
        </TextLink>
      )}
    </>
  );
};

export default YmmSelector;
