import { useState, useContext, useEffect, useRef } from "react";

import AutoComplete from "Clutch/Atoms/Form/Elements/AutoComplete/AutoComplete";
import Button from "Clutch/Molecules/Button";
import Typography from "Clutch/Atoms/Typography"

import { IsUserBot } from "Clutch//Utilities/CookieUtilties/UserCookieUtilities";
import { useVehicleStore } from "Clutch/Stores/VehicleStore";
import { useThemeStore } from "Clutch/Stores/ThemeStore";
import { useFitmentStateContext } from "../../../../../providers/FitmentStateProvider";
import { NavigationContext } from "Clutch/Contexts/Navigation/NavigationContext";
import { getProperUrl } from "Clutch/Contexts/VehicleContext/CoreVehicleRequests";
import {
  createClickedSegmentEvent,
} from "Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents";

import classNames from "classnames";
import styles from "../YmmSelector.module.scss";

const initialDropdownState = {
  engineMake: {
    value: null,
    label: null,
  },
  engineFamily: {
    value: null,
    label: null,
  },
  engineDisplacement: {
    value: null,
    label: null,
  },
};

const EngineContent = ({ isEnabled }) => {
  const [errorState, setErrorState] = useState(false);
  const [dropdownState, setDropdownState] = useState(initialDropdownState);

  const engineMakes = useVehicleStore((x) => x.context.engineMakes);
  const engineFamilies = useVehicleStore((x) => x.context.engineFamilies);
  const engineDisplacements = useVehicleStore(
    (x) => x.context.engineDisplacements
  );
  const reloadCurrentLocation = useVehicleStore(
    (x) => x.context.reloadCurrentLocation
  );

  const fetchEngineData = useVehicleStore((x) => x.fetchEngineData);
  const setIsLoading = useThemeStore((x) => x.setIsLoading);
  const addCustomerProject = useVehicleStore((x) => x.addCustomerProject);
  const selectCustomerProject = useVehicleStore((x) => x.selectCustomerProject);

  const { state: navigationState } = useContext(NavigationContext);
  const { garage } = navigationState;
  const { isCustomerProjectDrawerOpen } = garage;
  const { isFindPartsClicked, setIsFindPartsClicked } = useFitmentStateContext();

  const engineMakeDropdownRef = useRef();
  const engineFamilyDropdownRef = useRef();
  const engineDisplacementDropdownRef = useRef();

  useEffect(() => {
    if (isFindPartsClicked && isEnabled) {
      setIsFindPartsClicked(false);
      handleButtonClick();
    }
  }, [isFindPartsClicked, isEnabled]);

  useEffect(() => {
    if (IsUserBot())
        return false

    const { engineMake, engineFamily, engineDisplacement } = dropdownState;
    if (engineMake.value && engineFamily.value && engineDisplacement.value)
      addEngine();
    else {
      fetchEngineData(
        engineMake.value,
        engineFamily.value,
        null,
        null
      );
    }
  }, [dropdownState]);
  
  useEffect(() => {
    if (
      engineFamilyDropdownRef?.current &&
      !isCustomerProjectDrawerOpen &&
      dropdownState.engineMake?.value &&
      !dropdownState.engineFamily?.value
    ) {
      engineFamilyDropdownRef.current.focus();
    }
  }, [engineFamilies]);


  useEffect(() => {
    if (dropdownState?.engineMake?.value && errorState) {
      setErrorState("");
    }
  }, [errorState, dropdownState?.engineMake?.value]);

  useEffect(() => {
    if (dropdownState?.engineFamily?.value && errorState) {
      setErrorState("");
    }
  }, [errorState, dropdownState?.engineFamily?.value]);

  const addEngine = async () => {
    setIsLoading(true);
    try {
      const engineMakeKey = dropdownState.engineMake.value;
      const engineFamilyKey = dropdownState.engineFamily.value;
      const engineDisplacementKey = dropdownState.engineDisplacement.value;

      let manufacturerId;
      let makeId;
      let seriesId;
      let configurationId;
      let displacementId;
      let versionId;
      let qualifierId;

      if (engineDisplacementKey && engineDisplacementKey.length > 0) {
        var engineDisplacementIds = engineDisplacementKey.split("-");
        manufacturerId = engineDisplacementIds[0];
        makeId = engineDisplacementIds[1];
        seriesId = engineDisplacementIds[2];
        configurationId = engineDisplacementIds[3];
        displacementId = engineDisplacementIds[4];
        if (engineDisplacementIds.length > 6) {
          versionId = engineDisplacementIds[5];
          qualifierId = engineDisplacementIds[6];
        }
      } else if (engineFamilyKey && engineFamilyKey.length > 0) {
        var engineFamilyIds = engineFamilyKey.split("-");
        manufacturerId = engineFamilyIds[0];
        makeId = engineFamilyIds[1];
        seriesId = engineFamilyIds[2];
        configurationId = engineFamilyIds[3];
      } else if (engineMakeKey && engineMakeKey.length > 0) {
        var engineMakeIds = engineMakeKey.split("-");
        manufacturerId = engineMakeIds[0];
        makeId = engineMakeIds[1];
      }

      const engineIds = {
        engineManufacturerId: parseInt(manufacturerId, 10),
        engineMakeId: parseInt(makeId, 10),
        engineSeriesId: parseInt(seriesId, 10),
        engineConfigurationId: parseInt(configurationId, 10),
      };

      if (displacementId)
        engineIds.engineDisplacementId = parseInt(displacementId, 10);
      if (versionId) engineIds.engineVersionId = parseInt(versionId, 10);
      if (qualifierId) engineIds.engineQualifierId = parseInt(qualifierId, 10);

      if (
        engineIds.engineDisplacementId === 1 &&
        engineIds.engineVersionId != null &&
        engineIds.engineQualifierId != null &&
        (engineIds.engineVersionId > 1 || engineIds.engineQualifierId > 1)
      ) {
        engineIds.engineDisplacementId = null;
      }

      const response = await addCustomerProject({
        engineIds
      });
      const addedProjectId = response.addedCustomerProjectId;

      if (!response) {
        setIsLoading(false);
        return;
      }

      const selectedProject = response.projects.find(
        (project) => project.projectId === addedProjectId
      );
      const url = getProperUrl(selectedProject);
      await selectCustomerProject(selectedProject.projectId, url);

      if (reloadCurrentLocation) {
        // return false or it skips to line below
        window.location.reload();
        return false;
      }
      window.location.href = url;
    } catch (ex) {
      console.error(ex);
    }
    setIsLoading(false);
  };

  const handleButtonClick = () => {
    if (
        !dropdownState.engineMake?.value && 
        engineMakeDropdownRef?.current &&
        !isCustomerProjectDrawerOpen
    ) {
        engineMakeDropdownRef.current.focus()
        setErrorState(true)
    } else if (
        dropdownState.engineMake?.value && 
        !dropdownState.engineFamily?.value && 
        !isCustomerProjectDrawerOpen && 
        engineFamilyDropdownRef?.current
    ) {
        engineFamilyDropdownRef.current.focus();
        setErrorState(true)
    } else if (
        dropdownState.engineMake?.value && 
        dropdownState.engineFamily?.value && 
        !isCustomerProjectDrawerOpen
    ) {
        addEngine()
    }
  };

  return (
    <div className={styles.modalPanel}>
      <div className={styles.container}>
        <EngineDropdown
          dropdownData={engineMakes}
          onChange={(option) => {
            setDropdownState(() => ({
              ...initialDropdownState,
              engineMake: {
                value: option.value,
                label: option.label,
              },
            }));
          }}
          placeholder={"Select an Engine Make"}
          selectedValue={dropdownState.engineMake}
          label={"Engine Make"}
          isErrorState={errorState}
          selectRef={engineMakeDropdownRef}
        />
        <EngineDropdown
          dropdownData={dropdownState.engineMake?.value && engineFamilies}
          onChange={(option) => {
            setDropdownState((prevState) => ({
              ...initialDropdownState,
              engineMake: prevState.engineMake,
              engineFamily: {
                value: option.value,
                label: option.label,
              },
            }));
          }}
          placeholder={"Select a Family"}
          selectedValue={dropdownState.engineFamily}
          label={"Family"}
          isErrorState={errorState}
          selectRef={engineFamilyDropdownRef}
        />
        <EngineDropdown
          dropdownData={dropdownState.engineFamily?.value && engineDisplacements}
          onChange={(option) => {
            setDropdownState((prevState) => ({
              ...initialDropdownState,
              engineMake: prevState.engineMake,
              engineFamily: prevState.engineFamily,
              engineDisplacement: {
                value: option.value,
                label: option.label,
              },
            }));
          }}
          placeholder={"Select a Displacement"}
          selectedValue={dropdownState.engineDisplacement}
          label={"Displacement (Optional)"}
          isErrorState={errorState}
          selectRef={engineDisplacementDropdownRef}
        />
        <div className={styles.engineDropdown}>
          <Button
            segmentEvent={createClickedSegmentEvent("OpenModalHomePage")}
            brand={"secondary"}
            fill
            size={"small"}
            type={"button"}
            onClick={handleButtonClick}
            testingName={"findPartsEngine"}
          >
            <Typography
              className={styles.buttonText}
              tone={"contrast"}
              size={0.75}
              font={"bold"}
              inline
            >
              Find Parts
            </Typography>
          </Button>
        </div>
      </div>
    </div>
  );
};

const EngineDropdown = ({
  label,
  placeholder,
  selectedValue,
  dropdownData,
  onChange,
  selectRef,
  isErrorState,
}) => {
  let options = [];

  let emptyStyling = "";

  if (dropdownData?.length > 0) {
    dropdownData.forEach((data) => {
      options.push({
        label: data.displayName,
        value: data.optionId,
      });
    });
  } else {
    emptyStyling = styles.streetEmptyDropdown;
  }

  var isDisabled = !dropdownData || dropdownData.length === 0;

  return (
    <div
      className={`${classNames(styles.engineDropdown, emptyStyling)} ${
        isDisabled ? "" : `lr-${label}Selector`
      }`}
    >
      <AutoComplete
        options={options}
        onChange={(value) => onChange(value)}
        label={label}
        selectedValue={selectedValue}
        isDisabled={isDisabled}
        placeholder={placeholder}
        ref={selectRef}
        isErrorState={isErrorState}
        useNativeSelect={false}
      />
    </div>
  );
};

export default EngineContent;
