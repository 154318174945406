import { useContext, useState } from "react";

import Svg from "Clutch/Atoms/Svg";
import Button from "Clutch/Molecules/Button";

import { createClickedSegmentEvent } from "Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents";
import { NavigationContext } from "Contexts/Navigation/NavigationContext";
import {
  fitmentStates,
  useFitmentStateContext,
} from "../../../../providers/FitmentStateProvider";
import { getVehiclePlaceholder } from "../../desktop-cta/ymm-selector/tab-content/tabContentUtils";
import { getProperUrl } from "Contexts/VehicleContext/CoreVehicleRequests";
import useFeatureFlag from "Clutch/Hooks/useFeatureFlag/useFeatureFlag";

import styles from "./MobileGarageButton.module.scss";

const MobileGarageButton = ({ audience }) => {
  const [loading, setLoading] = useState(false);

  const { fitmentState, selectedCustomerProject, setCurrentTabForAudience } = useFitmentStateContext();
  const { setYmmModal, dispatch: navigationDispatch } = useContext(NavigationContext);

  const placeholder = getVehiclePlaceholder(selectedCustomerProject, audience);

  const impressionDontDelete = useFeatureFlag("Engine_Fitment_Master_Flag");
  const customerProjectDrawerEnabled = useFeatureFlag(
    "FrontEnd_CustomerProject_Drawer_Enabled"
  );

  const contentClassNames = `${styles.content} p p-color-contrast p-bold`;
  let buttonProps = null;

  switch (fitmentState) {
    case fitmentStates.vehicleSelected:
      buttonProps = {
        onClick: (e) => {
          e.stopPropagation();
        },
        isWaiting: loading,
        children: (
          <div className={styles.vehicleSelectedWrapper}>
            <p
              className={`${styles.selectedCustomerProjectContent} ${styles.selectedCustomerProjectText} p p-color-contrast p-bold`}
              style={{ flexGrow: 1, textAlign: "left" }}
              onClick={(e) => {
                e.stopPropagation();
                setLoading(true);
                const url = getProperUrl(selectedCustomerProject)
                window.location.href = url ?? "/shop/all";
              }}
            >
              {placeholder}
            </p>
            <Svg
              size={1}
              icon={"chevron"}
              tone={"contrast"}
              style={{ marginLeft: "0.25rem", transform: "rotate(90deg)" }}
              onClick={(e) => {
                e.stopPropagation();
                if (customerProjectDrawerEnabled) {
                  navigationDispatch({
                    type: "setCustomerProjectDrawerState",
                    payload: {
                      garageState: {
                        isCustomerProjectDrawerOpen: true
                      },
                    },
                  })
                } else {
                  setYmmModal(true);
                }
              }}
            />
          </div>
        ),
      };
      break;
    case fitmentStates.noVehicleSelected:
      buttonProps = {
        onClick: (e) => {
          e.stopPropagation();
          if (customerProjectDrawerEnabled) {
            navigationDispatch({
              type: "setCustomerProjectDrawerState",
              payload: {
                garageState: {
                  isCustomerProjectDrawerOpen: true
                },
              },
            })
          }
          else {
            navigationDispatch({
              type: "setGarageState",
              payload: {
                garageState: { isGarageModalOpen: true },
              },
            });
          }
        },
        children: <p className={contentClassNames}>{placeholder}</p>,
      };

      break;
    case fitmentStates.noVehiclesInGarage:
      buttonProps = {
        testingName: "add-vehicle-button",
        onClick: (e) => {
          e.stopPropagation();
          setCurrentTabForAudience(audience);
          if (customerProjectDrawerEnabled) {
            navigationDispatch({
              type: "setCustomerProjectDrawerState",
              payload: {
                garageState: {
                  isCustomerProjectDrawerOpen: true
                },
              },
            })
          } else {
            setYmmModal(true);
          }
        },
        children: (
          <div className={contentClassNames}>
            <Svg
              size={1}
              icon={"garage"}
              tone={"contrast"}
              style={{ marginRight: "0.25rem" }}
            />
            {placeholder}
          </div>
        ),
      };
      break;
  }

  return (
    <Button
      brand={"secondary"}
      size={"small"}
      fill
      type={"button"}
      className={styles.button}
      {...buttonProps}
      segmentEvent={createClickedSegmentEvent('MobileGarageHomePage')}
    />
  );
};

export default MobileGarageButton;
