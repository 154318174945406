import React, { useContext, useEffect, useRef, useState } from "react";

import { IsUserBot } from "@speedwaymotors/clutch/Utilities/CookieUtilties/UserCookieUtilities";
import AutoComplete from "Clutch/Atoms/Form/Elements/AutoComplete/AutoComplete";
import Typography from "Clutch/Atoms/Typography";
import useSegment from "Clutch/Hooks/useSegment/useSegment";
import Button from "Clutch/Molecules/Button";
import { useThemeStore } from "Clutch/Stores/ThemeStore";
import { useVehicleStore } from "Clutch/Stores/VehicleStore";
import { createClickedSegmentEvent } from "Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents";
//dont import dynamically
import { NavigationContext } from "Contexts/Navigation/NavigationContext";
import { getProperUrl } from "Contexts/VehicleContext/CoreVehicleRequests";
import { useFeatureFlag } from "Clutch/Hooks/useFeatureFlag/useFeatureFlag";
import getEngineIds from "Utilities/CustomerProjectUtilities/getEngineIds";

import ClassNames from "classnames";

import { useFitmentStateContext } from "../../../../../providers/FitmentStateProvider";
import styles from "../YmmSelector.module.scss";

const initialDropdownState = {
  year: {
    value: null,
    label: null,
  },
  make: {
    value: null,
    label: null,
  },
  model: {
    value: null,
    label: null,
  },
  stockEngine: {
    value: null,
    label: null,
  },
};

const StreetTab = ({ isEnabled }) => {
  const [dropdownState, setDropdownState] = useState(initialDropdownState);
  const [errorState, setErrorState] = useState("");
  
  const makes = useVehicleStore((x) => x.context.makes);
  const prioritizedMakes = useVehicleStore((x) => x.context.prioritizedMakes);
  const allYears = useVehicleStore((x) => x.context.allYears);
  const models = useVehicleStore((x) => x.context.models);
  const stockEngines = useVehicleStore((x) => x.context.stockEngines);
  const reloadCurrentLocation = useVehicleStore((x) => x.context.reloadCurrentLocation);

  const setIsLoading = useThemeStore((x) => x.setIsLoading);
  const fetchYmmData = useVehicleStore(x => x.fetchYmmData)
  const setExistingCustomerProjectState = useVehicleStore(x => x.setExistingCustomerProjectState)
  const addCustomerProject = useVehicleStore((x) => x.addCustomerProject);
  const selectCustomerProject = useVehicleStore((x) => x.selectCustomerProject);

  const { state: navigationState, dispatch: navigationDispatch } = useContext(NavigationContext);
  const { garage } = navigationState;
  const { isYmmModalOpen, isCustomerProjectDrawerOpen } = garage;
  const { sendCustomSegmentTrackEvent } = useSegment();
  const { isFindPartsClicked, setIsFindPartsClicked } = useFitmentStateContext();

  const impressionDontDelete = useFeatureFlag("Engine_Fitment_Master_Flag")
  const customerProjectDrawerEnabled = useFeatureFlag(
    "FrontEnd_CustomerProject_Drawer_Enabled"
  );

  useEffect(() => {
    if (isFindPartsClicked && isEnabled) {
      setIsFindPartsClicked(false);
      handleButtonClick();
    }
  }, [isFindPartsClicked, isEnabled]);

  useEffect(() => {
    const { year, make, model, stockEngine } = dropdownState;

    if(IsUserBot())
      return false

    if (
      year.value &&
      make.value &&
      model.value &&
      ((stockEngine.value && stockEngine.value !== -1) 
        || !customerProjectDrawerEnabled)
    ) {
      addYmm();
    } else {
      fetchYmmData(year.value, make.value, model.value);
    }
  }, [dropdownState]);

  useEffect(() => {
    if (dropdownState.stockEngine.value === -1) {
      const event = createClickedSegmentEvent("Engine Swap Option");
      sendCustomSegmentTrackEvent(event);
      setExistingCustomerProjectState(dropdownState)
      navigationDispatch({
        type: "setCustomerProjectDrawerState",
        payload: {
          garageState: {
            isCustomerProjectDrawerOpen: true,
            customerProjectDrawerView: "ADD_YMM"
          },
        },
      })
    }
  }, [dropdownState.stockEngine.value === -1]);

  const addYmm = async () => {
    setIsLoading(true);
    try {
      const year = dropdownState.year.value;
      const make = dropdownState.make.label;
      const makeId = dropdownState.make.value;
      const model = dropdownState.model.label;
      const modelId = dropdownState.model.value;
      const engineDefinitionId = dropdownState.stockEngine.value;

      const selectedStockEngine = stockEngines?.find(
        (x) => x.optionId === engineDefinitionId
      );

      const engineIds = getEngineIds(
        false,
        false,
        selectedStockEngine
      );

      const response = await addCustomerProject({
          year,
          make,
          makeId,
          model,
          modelId,
          engineIds,
        });
      const addedOrUpdatedProjectId = response.addedCustomerProjectId;

      if (!response) {
        setIsLoading(false);
        return;
      }

      const selectedProject = response.projects.find(
        (project) => project.projectId === addedOrUpdatedProjectId
      );
      const url = getProperUrl(selectedProject);

      await selectCustomerProject(selectedProject.projectId, url);
      if (reloadCurrentLocation) {
        // return false or it skips to line below
        window.location.reload();
        return false;
      }
      window.location.href = url;
    } catch (ex) {
      console.error(ex);
    }
    setIsLoading(false);
  };

  const yearRef = useRef();
  const makeRef = useRef();
  const modelRef = useRef();
  const stockEngineRef = useRef();

  useEffect(() => {
    if (
      makeRef?.current &&
      !isYmmModalOpen &&
      !isCustomerProjectDrawerOpen &&
      !dropdownState?.make?.value
      && dropdownState?.year?.value
    ) {
      makeRef.current.focus();
    }
  }, [makes]);

  useEffect(() => {
    if (modelRef?.current && !dropdownState?.model?.value
        && dropdownState?.make?.value
       && !isYmmModalOpen && !isCustomerProjectDrawerOpen) {
      modelRef.current.focus();
    }
  }, [models]);

  useEffect(() => {
    if (dropdownState?.year?.value && errorState === "year") {
      setErrorState("");
    }
  }, [errorState, dropdownState?.year?.value]);

  useEffect(() => {
    if (dropdownState?.make?.value && errorState === "make") {
      setErrorState("");
    }
  }, [errorState, dropdownState?.make?.value]);

  useEffect(() => {
    if (dropdownState?.model?.value && errorState === "model") {
      setErrorState("");
    }
  }, [errorState, dropdownState?.model?.value]);

  const handleButtonClick = () => {
    if (
      dropdownState?.year?.value === null &&
      yearRef?.current &&
      !isYmmModalOpen &&
      !isCustomerProjectDrawerOpen
    ) {
      yearRef.current.focus();
      setErrorState("year");
    } else if (
      dropdownState?.make?.value === null &&
      makeRef?.current &&
      !isYmmModalOpen &&
      !isCustomerProjectDrawerOpen
    ) {
      makeRef.current.focus();
      setErrorState("make");
    } else if (
      dropdownState?.model?.value === null &&
      modelRef?.current &&
      !isYmmModalOpen &&
      !isCustomerProjectDrawerOpen
    ) {
      modelRef.current.focus();
      setErrorState("model");
    } else if (
      dropdownState?.year?.value &&
      dropdownState?.make?.value && 
      dropdownState?.model?.value &&
      !isYmmModalOpen && 
      !isCustomerProjectDrawerOpen
    ) {
      addYmm()
    }
  }; 

  return (
    <div className={styles.modalPanel}>
      <div className={styles.container}>
        <YmmDropdown
          placeholder={"Select a Year"}
          label={"Year"}
          selectedValue={dropdownState.year}
          dropdownData={allYears}
          onChange={(option) => {
            setDropdownState(() => ({
              ...initialDropdownState,
              year: {
                value: parseInt(option.value, 10),
                label: option.label,
              },
            }));
          }}
          selectRef={yearRef}
          isErrorState={errorState === "year"}
        />
        <YmmDropdown
          placeholder={"Select a Make"}
          label={"Make"}
          selectedValue={dropdownState.make}
          dropdownData={dropdownState.year?.value && makes}
          onChange={(option) => {
            setDropdownState((prevState) => ({
              ...initialDropdownState,
              year: prevState.year,
              make: {
                value: parseInt(option.value, 10),
                label: option.label,
              },
            }));
          }}
          prioritizedMakes={prioritizedMakes}
          selectRef={makeRef}
          isErrorState={errorState === "make"}
        />
        <YmmDropdown
          placeholder={"Select a Model"}
          label={"Model"}
          selectedValue={dropdownState.model}
          dropdownData={dropdownState.make?.value && models}
          onChange={(option) => {
            setDropdownState((prevState) => ({
              ...initialDropdownState,
              year: prevState.year,
              make: prevState.make,
              model: {
                value: parseInt(option.value, 10),
                label: option.label,
              },
            }));
          }}
          selectRef={modelRef}
          isErrorState={errorState === "model"}
        />
        {customerProjectDrawerEnabled && 
          <YmmDropdown
            placeholder={"Select an Engine"}
            label={"Engine (Optional)"}
            selectedValue={dropdownState.stockEngines}
            dropdownData={dropdownState.model?.value && stockEngines}
            onChange={(option) => {
              console.log("New Engine Interaction");
              setDropdownState((prevState) => ({
                ...initialDropdownState,
                year: prevState.year,
                make: prevState.make,
                model: prevState.model,
                stockEngine: {
                  value: parseInt(option.value, 10),
                  label: option.label,
                },
              }));
            }}
            selectRef={stockEngineRef}
          />
        }
        <div className={styles.ymmModalItem}>
          <Button
            segmentEvent={createClickedSegmentEvent("OpenYmmModalHomePage")}
            brand={"secondary"}
            fill
            size={"small"}
            type={"button"}
            onClick={handleButtonClick}
            testingName={"findPartsStreet"}
          >
            <Typography
              className={styles.buttonText}
              tone={"contrast"}
              size={0.75}
              font={"bold"}
              inline
            >
              Find Parts
            </Typography>
          </Button>
        </div>
      </div>
    </div>
  );
};

const YmmDropdown = ({
  label,
  placeholder,
  selectedValue,
  dropdownData,
  onChange,
  prioritizedMakes,
  selectRef,
  isErrorState,
}) => {
  let options = [];

  if (prioritizedMakes?.length > 0) {
    prioritizedMakes.forEach((data) => {
      options.push({
        label: data.displayName,
        value: data.optionId,
      });
    });
  }

  let emptyStyling = "";

  if (dropdownData?.length > 0) {
    dropdownData.forEach((data) => {
      options.push({
        label: data.displayName,
        value: data.optionId,
      });
    });
  } else {
    emptyStyling = styles.streetEmptyDropdown;
  }

  var isDisabled = !dropdownData || dropdownData.length === 0;

  return (
    <div
      className={`${ClassNames(styles.ymmModalItem, emptyStyling)} ${
        isDisabled ? "" : `lr-${label}Selector`
      }`}
    >
      <AutoComplete
        options={options}
        onChange={(value) => onChange(value)}
        label={label}
        selectedValue={selectedValue}
        isDisabled={isDisabled}
        placeholder={placeholder}
        ref={selectRef}
        isErrorState={isErrorState}
        useNativeSelect={false}
      />
    </div>
  );
};

export default StreetTab;
